	;TSCUtils = {
			topics : {},
			storeId : "",
			catalogId : "",
			langId : "-1",
			ua : navigator.userAgent,
			emailformat : /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$/i,
			numericRegex : /[0-9]/,
			zipCodeRegex : /^(\d{5})?$/,
			days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			setCommonParameters:function(langId,storeId,catalogId){
				this.langId = langId;
				this.storeId = storeId;
				this.catalogId = catalogId;
			},
			getElementsbyAttr : function (attrib) {
				return document.querySelectorAll('[' + attrib + ']');
			},
			getElementsByClassName : function (className) {
				return document.getElementsByClassName(className);
			},
			setFocus : function (ele) {
				document.getElementById(ele).focus();
			},
			subscribe: function(topic, listener) {
				  // Create the topic's object if not yet created
				  if(!this.topics[topic]) this.topics[topic] = { queue: [] };
				  // Add the listener to queue
				  var index = this.topics[topic].queue.push(listener) - 1;
				  // Provide handle back for removal of topic
				  return (function(topic, index) {
					return {
					  remove: function() {
						delete this.topics[topic].queue[index];
					  }
					}	
				  })(topic, index);
			},
			publish: function(topic, info) {
				  // If the topic doesn't exist, or there's no listeners in queue, just leave
				  if(!this.topics[topic] || !this.topics[topic].queue.length) return;
				  // Cycle through this.topics queue, fire!
				  var items = this.topics[topic].queue;
				  for(var i = 0, len = items.length; i < len; i++) {
					if(typeof items[i] === 'function') items[i](info || {});
				  }
			},
			getCookie : function (cname) {
				  var name = cname + "=";
				    var ca = document.cookie.split(';');
				    for(var i = 0; i < ca.length; i++) {
				        var c = ca[i];
				        while (c.charAt(0) == ' ') {
				            c = c.substring(1);
				        }
				        if (c.indexOf(name) == 0) {
				            return decodeURIComponent(c.substring(name.length, c.length));
				        }
				    }
				    return "";
				
			} ,
			setCookie : function (cookieName, cookieVal, props) {
				var createCookie = function(name, value, props){
					if (!props) {
						props = {};
					}

					var exp = props.expires;
					if (exp) {
						if(typeof exp == "number"){
							var d = new Date();
							d.setTime(d.getTime() + exp*24*60*60*1000);
							exp = d;
						}
						if (exp.toUTCString){
							props.expires = exp.toUTCString();
						} else {
							props.expires = exp;
						}
					}

					value = encodeURIComponent(value);
					var propString = name + "=" + value;
					for(var name in props){
						propString += "; " + name;
						var propValue = props[name];
						if(propValue !== true){
							propString += "=" + propValue;
						}
					}
					document.cookie = propString;
				};

				if (!props) {
					createCookie(cookieName, cookieVal);
				} else {
					var cookieProps = props;
					for (var propName in cookieProps) {
						if (!cookieProps[propName]) {
							delete cookieProps[propName];
						}
					}
					createCookie(cookieName, cookieVal, cookieProps);
				}
			},
			removeCookie : function(name, props){
				var cookieStr = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
				if(!this.isNullObj(props.path)){
					cookieStr += 'path='+props.path;
				}
				document.cookie = cookieStr;
			},
			hideifexist : function (ele) {
				if(this.byId(ele)) { 
					document.getElementById(ele).style.display = "none";
				}
			},
			hide : function (ele) {
				document.getElementById(ele).style.display = "none";
			},
			showifexists : function (ele) {
				if(this.byId(ele)) { 
					document.getElementById(ele).style.display = "block";
				}
			},
			show : function (ele) {
				document.getElementById(ele).style.display = "block";
			},
			setElementListDisplayBlock : function (e) {
				for(var i = 0 ; i < e.length ; i++ ) {
						if(byId(ele[i])) { 
							document.getElementById(ele[i]).style.display = "block";
						}
				}
			},
			setElementListDisplayNone : function (e) {
				for(var i = 0 ; i < e.length ; i++ ) {
					if(this.byId(ele[i])) { 
						document.getElementById(ele[i]).style.display = "none";
					}
				}
			},			
			getInnerHTML : function (ele) {
					if(this.byId(ele)) { 
						return document.getElementById(ele).innerHTML;
					}
			},
			setInnerHTML : function (ele , val) {
					if(this.byId(ele)) { 
						document.getElementById(ele).innerHTML = val;
					}
			},
			getValifexists : function (ele) {
				if(this.byId(ele)) { 
					return document.getElementById(ele).value;
				}
				return "";
			},
			getVal : function (ele) {
				return document.getElementById(ele).value;
			},
			setValIfNotNull : function (ele , val) {
				if(this.byId(ele)) { 
					document.getElementById(ele).value = val;
				}
			},
			setVal : function (ele , val) {
					document.getElementById(ele).value = val;
			},
			hasClass: function(el , className) {
				if (el.classList) {
					return el.classList.contains(className);
				} else {
					return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
				}
			},
			hasClassForId : function(el , className) {
				if(!this.byId(el)) {
					return;
				}
				el = this.byId(el);
				if (el.classList) {
					return el.classList.contains(className);
				} else {
					return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
				}
			},
			addClass : function (el , className) {
				if(typeof el === "string"){
					if(!this.byId(el)) {
						return;
					}
					el = this.byId(el);
				}
				if (el.classList)
					el.classList.add(className);
				else if (!this.hasClass(el, className)) el.className += " " + className;
			},
			removeClass : function (el, className) {
				if(typeof el === "string"){
					if(!this.byId(el)) {
						return;
					}
					el = this.byId(el);
				}
				 if (el.classList)
					el.classList.remove(className);
				else if (this.hasClass(el, className)) {
					var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
					el.className=el.className.replace(reg, ' ');
				}
			},
			setAttributeByEleName : function (ele , attrname , val) {
					document.getElementById(ele).setAttribute(attrname , val);
			},
			getAttributeByEleName : function  (ele , attrname) {
				return document.getElementById(ele).getAttribute(attrname);
			},
			setAttribute : function (ele , attrname , val) {
				ele.setAttribute(attrname , val);
			},
			getAttribute : function (ele , attrname ) {
				ele.getAttribute(attrname);
			},
			byId : function (r){
				return document.getElementById(r);
			},
			trim : function (s) {
				return typeof s !== 'undefined' && s !== null ? s.trim() : '';
			},
			isEmpty: function(val) {
				return typeof val === 'undefined' || val === 'undefined' || val === null || trim('' + val).length === 0;
			},
			isNullObj : function(obj) {
				if (typeof obj != "undefined" && obj != undefined && obj !=null && obj != "") {
					return false;
				}else{
					return true;
				}
			},
			isElementVisible : function(elementToBeChecked) {
				var TopView = window.pageYOffset ; 
				var BotView = TopView + window.innerHeight;
				// To return true before 100px of the emelment visibility
				var TopElement = - 100;
				var BotElement;
				if(typeof elementToBeChecked === "string"){
					TopElement += document.getElementById(elementToBeChecked).offsetTop;
					BotElement = TopElement + document.getElementById(elementToBeChecked).offsetHeight;
				}else{
					TopElement += elementToBeChecked.offsetTop;
					BotElement = TopElement + elementToBeChecked.offsetHeight;
				}
				return ((BotElement <= BotView) && (TopElement >= TopView));
			},			
			getParameterByName : function (name) {
				name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
				var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
				results = regex.exec(location.search);
				return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));	
			},
			keys : function (obj) {	  
				var ret = [];
				 for (var prop in obj) {
					 if (obj.hasOwnProperty(prop)){ 
						 ret.push(prop);
					}
				 }
				return ret;
			},
			resetModelProps : function  (obj) {
				this.keys(obj).forEach(function(key) { delete obj[key]; });
			} ,
			format : function(format){
			    var args = Array.prototype.slice.call(arguments, 1);
			    return format.replace(/\{(\d+)\}/g, function(m, i){
			        return args[i];
			    });
			},
			formatMessage : function(format){
			    var args = Array.prototype.slice.call(arguments, 1);
			    return format.replace(/\${(\d+)\}/g, function(m, i){
			        return args[i];
			    });
			},
			checkedStatus : function (ele) {
				if(! this.byId(ele)) {
					return false;
				}
				 return document.getElementById(ele).checked;
			},
			requires : function (fileObj , options) {
				var dir = imageDirectoryPath.replace(/&#x2f;/g,'/');
				options = options || {};
				if(!!fileObj.js){
					fileObj.js.forEach(function (scriptName) {
						if(options['dir']) {
							dir = options['dir']; 
						}
						scriptName = dir + scriptName; 
						scriptName += scriptName.trim().toLowerCase().indexOf('.js')>0 ? '' : '.js';
						 if ($('head script[src="' + scriptName + '"]').length == 0) {
							 $('head').append(TSCUtils.format('<script type="text/javascript" src="{0}"></script>' , scriptName));
						}
					});
					if(options['cb']) {
						options['cb']();
					}
				}
				if(!!fileObj.css){
					fileObj.css.forEach(function (stylesheet , options) {
						stylesheet = imageDirectoryPath+ stylesheet;
						stylesheet += stylesheet.trim().toLowerCase().indexOf('.css')>0 ? '' : '.css';
						var ss = document.styleSheets;
						for (var i = 0, max = ss.length; i < max; i++) {
							var  slink = ss[i].href || "";
							if (slink.indexOf(stylesheet) == -1) {
								$('head').append(TSCUtils.format('<link rel="stylesheet" type="text/css" media="{1}" href="{0}" />', stylesheet, options.media || 'screen'));
									break;
							}
						}
					});
				}
			},
			isValidEmail: function(emailId){
				return this.emailformat.test(emailId);
			},
			detectIE : function () {
				  var ua = this.ua;	 
				  var msie = ua.indexOf('MSIE ');
				  if (msie > 0) {
					return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
				  }
				  var trident = ua.indexOf('Trident/');
				  if (trident > 0) {
					var rv = ua.indexOf('rv:');
					return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
				  }
				  var edge = ua.indexOf('Edge/');
				  if (edge > 0) {
					return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
				  }
				  return false;
			},
			isChrome : function (){
				return /Chrome/.test(this.ua) && /Google Inc/.test(navigator.vendor);
			},
			isSafari : function () {
				 return /Safari/.test(this.ua) && /Apple Computer/.test(navigator.vendor);
			},
			isIOS : function () {
				return this.ua.match(/(iPod|iPhone|iPad)/);
			},
			isAndroid : function () {
				return /(android)/i.test(this.ua);
			},
			isIOSChrome : function (){
				return /CriOS/.test(this.ua);
			},
			escapeXml : function (str, fullConversion){
				if(fullConversion){
					str = str.replace(/&(?!(quot;|#34;|#034;|#x27;|#39;|#039;))/gm, "&amp;").replace(/</gm, "&lt;").replace(/>/gm, "&gt;");
				}
				str = str.replace(/"/gm, "&#034;").replace(/'/gm, "&#039;");
				return str;
			},
			invokeService : function (serviceName , params) {
				if(typeof dojo!= "undefined") {
					wc.service.invoke(serviceName, params);
				}
			},
			scrollTop : function () {
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},
			verticalScrollPresent :function () {
			   return (document.documentElement.scrollHeight !== document.documentElement.clientHeight);
			},
			formatCurrency: function(symbol,amount){
				amount = parseFloat(amount, 10);
				if(!isNaN(amount)){
					var isNegetive = amount < 0;
					amount = Math.abs(amount).toFixed(2).toString();
					var response = symbol+amount.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
					return isNegetive ? "-"+response: response;
				}
			},
			quickInfoLoadBind: function(catalogEntryID, categoryIds){
				if(typeof isJQuery !== 'undefined' && isJQuery){
					$(document).ready(function(){
						QuickInfoJS.updatePriceForCatentry(catalogEntryID,'');   
						//shoppingActionsJS.setCategoryIds(categoryIds);
					});
				}else{
					dojo.addOnLoad(function() {
						QuickInfoJS.updatePriceForCatentry(catalogEntryID,'');   
						shoppingActionsJS.setCategoryIds(categoryIds);
					 });
				}
			},
			// This function excludes iPad, Tab, desktop devices
			isMobile : function(){
				var lowercaseUserAgent = this.ua.toLowerCase();
				if(TSCUtils.isAndroid() && lowercaseUserAgent.match(/mobile/) ){
					return true;
				}else if(TSCUtils.isIOS()){                                                                
					return Boolean(lowercaseUserAgent.match(/(iphone|ipod|opera mini)/));
				}else{                                                                    
					return Boolean(lowercaseUserAgent.match(/(windows phone os|windows ce|windows mobile|blackberry|opera mini|mobile)/));
				}                                                              
			},
			isNumeric: function(input){
				return this.numericRegex.test(input);
			},
			isValidZip: function(input){
				if(this.isNullObj(input)) return false; 
				return this.zipCodeRegex.test(input);
			},
			getClientWidth : function(){
				return document.documentElement.clientWidth;
			},
			addScript : function (scriptVal, fnName){
				var head = document.getElementsByTagName('head')[0]; 
				script = document.createElement('script');
				script.type = 'text/javascript';
				script.src=scriptVal;
				script.async = false;
		      
				if(fnName != undefined){
					script.onload = function(){
						var func = TSCUtils.convertStringtoFunction(fnName);//window["CheckoutHelperJS"]["loadModal"]; 		  
						if (typeof func === "function") func();
					}
				};
				head.appendChild(script);     
			},	
			convertStringtoFunction: function (functionName){
		        var nullFunc = function(){}; // Fallback Null-Function
		        var ret = window; // Top level namespace
		        // If null/undefined string, then return a Null-Function
		        if(functionName==null) return nullFunc;
		        // Convert string to function name
		        functionName.split('.').forEach(function(key){ ret = ret[key]; });
		        // If function name is not available, then return a Null-Function else the actual function
		        return (ret==null ? nullFunc : ret);
		    },
		    
		  //Function to find whether entered date is valid or not
		    isValidDate: function (date){
		    	try{
		    	     $.datepicker.parseDate('mm/dd/yy',date);
		    	}
		    	catch(e)
		    	{
		    		return false;
		    	}
		    	return true;
		    },
		    // Function to add new url params key/values or modify existing url param key values to passed URL string
		    modifyUrlParam: function (uri, key, val){
		    	 return uri
		         .replace(new RegExp("([?&]"+key+"(?=[=&#]|$)[^#&]*|(?=#|$))"), "&"+key+"="+encodeURIComponent(val))
		         .replace(/^([^?&]+)&/, "$1?");
		    },
		    // Function will return asked urlparam value from passed url.
		    getURLParamFieldValue: function (url,urlParamFieldName){
		    	var urlParamValue = null;
		    	var reg = new RegExp( '[?&]' + urlParamFieldName + '=([^&#]*)', 'i' );
		    	urlParamValue = reg.exec(url);
		    	if(urlParamValue!= null){
		    		urlParamValue = urlParamValue[1];
		    	}
		    	return urlParamValue;
		    },
		    // Function to get iOS version
		    getIOSVersion: function() {
		    	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		    	   var version = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		    	   return [parseInt(version[1], 10), parseInt(version[2], 10), parseInt(version[3] || 0, 10)];
		    	 }
		    },
		    //Function to escape ' and " for a given string
		    replaceChar: function(str) {
				str = str.replace(/&#39;/g, "\\&#39;").replace(/"/g, "&quot;");
				return str;
			},
		// This function denotes if the current search page is a marketing page or not.
			isMarketingPage: function(searchTerm){
				var returnVal = false;
				if(searchTerm == "**" || searchTerm.indexOf(" | ") > 0 || this.getParameterByName("cm_mmc") != "" || 
						  this.getParameterByName("cm_sp") != "" || this.getParameterByName("cm_re")){
					returnVal = true;
				}
				return returnVal;
			},
			// This function denotes if the referrer page is a marketing search page or not.
			isMarketingPageReferrer: function(){
				var returnVal = false;
				var referrer = document.referrer;
				if(!this.isNullObj(referrer) && referrer.indexOf("/search/")>-1 ){
					if(referrer.split('?').length > 1 && (referrer.split('?')[1].indexOf("cm_mmc") != -1 || 
							referrer.split('?')[1].indexOf("cm_sp") != -1 || referrer.split('?')[1].indexOf("cm_re") != -1)){
						returnVal = true;
					}
					if(!returnVal ){
						searchTerm = this.getReferrerKeyword();
						if(searchTerm == "**"  || decodeURIComponent(searchTerm).indexOf(" | ") > 0){
							returnVal = true;
						}
					}
					
				}
				return returnVal;
			},
			//This method gives keyword id the referrer is a search page, else it gives blank string.
			getReferrerKeyword: function(){
				var referrer = document.referrer;
				var searchTerm = "";
				if(!this.isNullObj(referrer) && referrer.indexOf("/search/")>-1 ){
					var paramURL = referrer.split('?')[0].split('#')[0].split('/search/')[1];
					searchTerm = decodeURIComponent(paramURL.split('/')[0]);
				}				
				return searchTerm;
			},
			//
			getFormattedDate: function(futureDate){
				var date = new Date(futureDate); 
 				var displayDate = this.days[date.getDay()]+', '+this.months[date.getMonth()]+' '+date.getDate();
 				return displayDate;
			},
			//date formatting supported by all browsers 
			getBrFormattedDate: function(futureDate){
				var splitDate = futureDate.split(' ');
				var dateElements = splitDate[0].split('-');
				var date = new Date(dateElements[0], dateElements[1]-1, dateElements[2]);
				var displayDate = this.days[date.getDay()]+', '+this.months[date.getMonth()]+' '+date.getDate();
 				return displayDate;
			},
			//date formatting to return in MMM DD, YYYY e.g. (Oct 18, 2021) in user friendly format
            getUFFormattedDate: function(futureDate){
                var date = new Date(futureDate);
                var displayDate = this.months[date.getMonth()]+' '+date.getDate()+', '+date.getFullYear();
                return displayDate;
            }		   
	}